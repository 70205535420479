import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'

const Om = ({ data }) => (
  <Layout
    pageTitle='Om Switsbake'
    backgroundImage={data.imageBackground.childImageSharp.gatsbyImageData}
  >
    <oma-grid-row class='row row--with-auto-margin'>
      <section className='section section--with-background'>
        <h1 className='heading page__heading'>Om Switsbake</h1>
        <p className='section__text'>
          Switsbake ett bageri med produktion och verksamhet i hjärtat av
          Sörmland, Malmköping. Vi ingår i en koncern med bagerier i bland annat
          Tyskland, Polen, Danmark, England.
        </p>
        <p className='section__text'>
          Switsbake har en lång och mycket god erfarenhet inom detalj-
          servicehandel, catering, försäljning och bagerinäring samt arbetar
          över hela Norden. Med oss blir det enkelt.
        </p>

        <p className='section__text'>
          Vi har specialiserat oss på produktion av olika typer av kaffebröd och
          vänder oss till dagligvaruhandel, servicehandel, restaurang och
          storkök. Vi är snabba, flexibla och arbetar med tydliga processer som
          underlättar för alla inblandade. En av våra styrkor är kunskapen kring
          produktutveckling och innovation inom bageri.
        </p>

        <p className='section__text'>
          Vi tror på öppenhet och ställer höga krav på oss själva och våra
          partners, vilket garanterar att du som kund alltid kan fokusera på din
          verksamhet. Tillsammans med dig som kund, beslutar vi vad som är målet
          med uppdraget, vilket vi sedan följer upp och säkerställer.
        </p>
      </section>

      <section className='section section--with-background'>
        <h1 className='heading page__heading'>Historia</h1>
        <p className='section__text'>
          Switsbake har sin historia i Upplandskubben sedan 1950-talet.
          Upplandskubben AB (1926-1991) var välkänt för sitt kokta bröd och
          bolaget blev därmed framgångsrikt långt utanför Uppland. För att
          utveckla sortimentet ytterligare så övertog Upplandskubben
          Switsvarumärket omkring 1950.
        </p>
        <p className='section__text'>
          Swits var då ett namn på ett bageri i Björkhagen, Stockholm, där
          maränger och cocosbollar m m tillverkades. Switsmärket kom sedan att
          användas som varumärke på alla de produkter som togs fram såsom;
          småkakor, strutar, krustader m m.
        </p>
        <p className='section__text'>
          I början av 60-talet förvärvades det som då hette Gästabudsfabriken i
          Malmköping och här började då tillverkning av tårtbottnar, mjuka
          kakor, rulltårtor, pepparkakor m m. Marängtillverkningen var
          fortfarande kvar på Lilla Essingen i Stockholm, där också huvudkontor
          och lager var beläget. Distribution till butiker skedde då med egna
          bilar och var som mest 16 st. Man levererade i huvudsak till
          Stockholms-, Upplands-, och Mälardalsområdet.
        </p>
        <p className='section__text'>
          Under 80- och 90-talet koncentrerades huvudkontor, produktion samt
          lagerverksamhet till Malmköping.
        </p>
      </section>
      <section className='section section--with-background'>
        <h1 className='heading page__heading'>Kvalitet</h1>
        <p className='section__text'>
          Kvalitet är ett nyckelord för oss på Switsbake. Vi producerar och
          utvecklar konkurrenskraftiga produkter på ett ansvarsfullt sätt med de
          bästa av råvaror.
        </p>
        <ul>
          <li>Vår anläggning i Malmköping är BRC-certifierad</li>
          <li>Vi använder oss av ägg från frigående höns</li>
          <li>Vi är RSPO-certifierade</li>
          <li>Vi använder inte GMO-modifierade råvaror</li>
        </ul>
      </section>
    </oma-grid-row>
  </Layout>
)

export const query = graphql`
  {
    imageBackground: file(relativePath: { eq: "chocolate-fika.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 60, layout: FULL_WIDTH)
      }
    }
  }
`

export default Om
